<script>
import { Component } from "vue-property-decorator";
import AbstractMenu from "@/layouts/AbstractMenu";

@Component({
  name: "Menu",
})
class Menu extends AbstractMenu {
  data() {
    return {
      // menu things
      rounded: null,
      inset: true,
      mini: 1,
      homePage: {
        link: {
          name: "ResHome",
        },
      },
      section0: [
        {
          text: "topMenuSP.agenda",
          id: "RS.topMenu.agenda",
          icon: "mdi-calendar-month",
          link: {
            name: "ResourceCalendar",
          },
        },
      ],
      side: 1,
      section1: [
        {
          text: "sideMenuSP.account", // TODO translate
          id: "RS.sideMenu.account",
          icon: "mdi-card-account-details-outline",
          link: {
            name: "ResourceMyAccount",
          },
        },
        {
          text: "sideMenuSP.param",
          id: "RS.sideMenu.parameters",
          icon: "mdi-cog",
          link: {
            name: "ResourceParameters",
          },
        },
      ],
    };
  }
}
export default Menu;
</script>
